import React from 'react'
import Layout from '../../../../components/Layout'
import MvTextPage from '../../../../components/common/MvTextPage'
import MvTextPageSection from '../../../../components/common/MvTextPageSection'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout>
    <MvTextPage title="Aufnahme Winterunterhaltung 2020">
      <MvTextPageSection>
        <p>
          Die Aufnahme findet ihr hier zum Download:{' '}
          <ul>
            <li>
              <a
                href={data.downloadTrachtenkapelle.publicURL}
                title="Aufnahme der Trachtenkapelle als ZIP herunterladen"
              >
                Trachtenkapelle 2020
              </a>
            </li>
            <li>
              <a
                href={data.downloadJugendkapelle.publicURL}
                title="Aufnahme der Jugendkapelle als ZIP herunterladen"
              >
                Jugendkapelle 2020
              </a>
            </li>
          </ul>
        </p>

        <p>
          Die Aufnahmen sind ausschließlich für den Privatgebrauch gedacht. Eine
          Veröffentlichung ist nicht erlaubt.
        </p>
      </MvTextPageSection>
    </MvTextPage>
  </Layout>
)

export const query = graphql`
  query {
    downloadTrachtenkapelle: file(
      relativePath: { eq: "download/aufnahmen/Trachtenkapelle2020.zip" }
    ) {
      publicURL
    }
    downloadJugendkapelle: file(
      relativePath: { eq: "download/aufnahmen/Jugendkapelle2020.zip" }
    ) {
      publicURL
    }
  }
`
